<template>
  <div class="earningsTop">
    <div class="title">
      <!-- <figure class="back">
        <img
          class="backIcon"
          src="~assets/img/drawableinfo/icon_shop_right.png"
        />
      </figure>
      <h3 class="titleName">收益管理</h3>
      <div></div>-->
    </div>
    <div class="infoBox">
      <div class="info">
        <!-- 可提现余额 -->
        <section class="withdrawTtitle">
          <p class="withdrawTtitleP">{{ $t.earningsInfo.myBalance }}</p>
          <img
            class="question"
            src="~assets/img/earnings/icon_earnings_question@2x.png"
          />
        </section>
        <!-- 提现金额 -->
        <van-row type="flex" align="center" class="withdrawBody">
          <van-col span="2" class="withdrawBodyUnit">$</van-col>
          <van-col span="16" class="withdrawBodyNum">{{ balance }}</van-col>
          <van-col
            span="7"
            class="withdrawBtBox"
            @click="changeType('withdraw', 'withdraw')"
            >{{ $t.earningsInfo.withdraw }}</van-col
          >
        </van-row>
        <!-- 总收益与冻结金额 -->
        <van-row type="flex" align="center" class="earningsAndBlocking">
          <van-col class="earningsTotal">
            <p class="earningsTotalTitle">{{ $t.earningsInfo.total }}</p>
            <van-row type="flex" align="center" class="earningsTotalBox">
              <van-col class="earningsTotalUnit">$</van-col>
              <van-col class="earningsTotalNum">{{ balance }}</van-col>
            </van-row>
          </van-col>
          <van-col class="earningsTotal">
            <p class="earningsTotalTitle">{{ $t.earningsInfo.frozen }}</p>
            <van-row type="flex" align="center" class="earningsTotalBox">
              <van-col class="earningsTotalUnit">$</van-col>
              <van-col class="earningsTotalNum">0.00</van-col>
            </van-row>
          </van-col>
        </van-row>
      </div>
    </div>
    <div class="bottom"></div>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col } from "vant";
Vue.use(Row).use(Col);

export default {
  name: "EarningsTop",
  props: {
    balance: {
      type: Number,
      default: 0.0,
    },
  },
  data() {
    return {
      data: "dd",
    };
  },
  methods: {
    //跳转页面
    changeType(component, name) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: component,
        name: name,
        query: [
          {
            name: "Money",
            value: this.balance,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin spanNowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin puiblicFlex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
.earningsTop {
  width: 100%;
  position: relative;
  //padding-top: 5%;
  // background-image: linear-gradient(to bottom right, #61cc89, #29b899);
  // background-image: -webkit-linear-gradient(to bottom right, #61cc89, #29b899);
  background: linear-gradient(left, #71d283, #01aaa3);
  .bottom {
    padding: 50px;
    background-color: #f6f6f6;
  }
  .title {
    width: 92%;
    margin: 0 auto;
    padding-bottom: 150px;
    @include puiblicFlex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    // padding-top: 50px;
    .back {
      margin: 0;
      padding: 0;
      @include puiblicFlex;
      .backIcon {
        width: 20px;
      }
    }
    .titleName {
      margin: 0;
      padding: 0;
      font-size: 30px;
      color: #ffffff;
      font-weight: 500;
    }
  }
  .infoBox {
    position: absolute;
    bottom: 0;
    width: 100%;
    .info {
      width: 92%;
      margin: 0 auto;
      border-radius: 15px;
      box-shadow: 0 0 0px 1px #ece7e7;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      background-color: #ffffff;
      padding: 20px;
      .withdrawTtitle {
        @include puiblicFlex;
        margin: 0;
        padding: 0;
        .withdrawTtitleP {
          margin: 0;
          padding: 0;
          font-size: 14px;
          color: #000000;
        }
        .question {
          width: 20px;
          margin-left: 10px;
        }
      }
      .withdrawBody {
        color: #43cd9a;
        margin-top: 10px;
        .withdrawBodyUnit {
          font-size: 18px;
          color: #000000;
        }
        .withdrawBodyNum {
          font-size: 30px;
          width: 66.66%;
        }
        .withdrawBtBox {
          width: 30%;
          border: none;
          text-align: center;
          padding: 0;
          font-size: 14px;
          border-radius: 40px;
          color: #fff;
          background: linear-gradient(left, #71d283, #01aaa3);
          margin-top: 10px;
          //padding: 18px 0;
          line-height: 2;
        }
      }
      .earningsAndBlocking {
        font-size: 14px;
        margin-top: 20px;
        .earningsTotal {
          width: 50% !important;
          .earningsTotalTitle {
            margin: 0;
            padding: 0;
            color: #000000;
          }
          .earningsTotalBox {
            width: 100%;
            margin-top: 10px;
            .earningsTotalUnit {
              width: 10%;
              font-size: 14px;
            }
            .earningsTotalNum {
              width: 90%;
              color: #43cd9a;
              font-size: 20px;
              @include spanNowrap();
            }
          }
        }
      }
    }
  }
}
</style>
