<template>
  <van-col class="item">
    <section class="indent">
      <p class="indentId">{{ $t.earningsInfo.withdraw }}</p>
      <p class="indentNum">{{ item.CreateTime }}</p>
    </section>
    <section class="numBox">
      <p class="depositPrice">{{ item.Money }} $</p>
      <p class="difference">
        {{ $t.earningsInfo.myBalance }} ${{ item.Balance }}
      </p>
    </section>
  </van-col>
</template>
<script>
import Vue from "vue";
import { Row, Col, List } from "vant";
Vue.use(Row).use(Col).use(List);
export default {
  name: "DepositList",
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@mixin spanNowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin puiblicFlex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
.item {
  width: 92%;
  margin: 0 auto;
  @include puiblicFlex;
  font-size: 14px;
  .indent {
    // @include puiblicFlex;
    text-align: left;
    width: 50%;
    .indentId {
      margin: 0;
      padding: 0;
      width: 100%;
      @include spanNowrap;
      font-size: 14px;
      color: #000000;
      font-weight: bold;
    }
    .indentNum {
      margin: 0;
      padding: 0;
      width: 100%;
      margin-top: 15px;
      @include spanNowrap;
      color: #ff5852;
      font-size: 14px;
      color: #7a7a7a;
    }
  }
  .numBox {
    width: 50%;
    text-align: right;
    .depositPrice {
      margin: 0;
      padding: 0;
      font-weight: bold;
      font-size: 14px;
    }
    .difference {
      margin: 0;
      padding: 0;
      color: #7a7a7a;
      font-size: 12px;
      margin-top:5px;
    }
  }
}
</style>
