<template>
  <van-col class="item">
    <section class="indent">
      <p class="indentId">{{ $t.earningsInfo.poNo }}：{{ item.Title }}</p>
      <p class="indentNum">+{{ item.Money }} $</p>
    </section>
    <p class="iteminfo">{{ item.Name }}&nbsp;&nbsp;{{ item.CreateTime }}</p>
  </van-col>
</template>
<script>
// 收款记录
import Vue from "vue";
import { Row, Col, List } from "vant";
Vue.use(Row).use(Col).use(List);
export default {
  name: "LollectionList",
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    moreOnLoad() {},
  },
};
</script>
<style lang="scss" scoped>
@mixin spanNowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin puiblicFlex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
.item {
  width: 92%;
  margin: 0 auto;
  .indent {
    @include puiblicFlex;
    width: 100%;
    .indentId {
      margin: 0;
      padding: 0;
      width: 60%;
      @include spanNowrap;
      font-size: 14px;
      color: #000000;
      font-weight: bold;
    }
    .indentNum {
      margin: 0;
      padding: 0;
      width: 40%;
      @include spanNowrap;
      // text-align: center;
      text-align: right;
      color: #ff5852;
      font-weight: bold;
      font-size: 14px;
    }
  }
  .iteminfo {
    font-size: 14px;
    color: #a7a7a7;
    margin: 0;
    padding: 0;
    margin-top: 15px;
  }
}
</style>
